import { cva, css } from '../styled-system/css';

import Icon from './Icon';

type ClickHandler =
  | ((arg: any | null) => void)
  | ((event: React.MouseEvent<HTMLButtonElement>) => void);

interface ButtonProps {
  type: 'primary' | 'secondary' | 'tertiary' | 'danger';
  label: string;
  icon?: string;
  showLabel?: boolean;
  dropdown?: boolean;
  disabled?: boolean;
  keyboardShortcut?: string;
  handleClick?: ClickHandler;
  htmlType?: 'button' | 'submit' | 'reset';
  form?: string;
  formAction?: string;
  formEncType?: string;
  formMethod?: string;
  formNoValidate?: boolean;
  formTarget?: string;
}

// Tooltip Handling - TODO: extract to own component
export const withTooltipStyle = css({
  position: 'relative',

  // Show tooltip on hover and focus
  '&:hover .tooltip, &:focus .tooltip': {
    opacity: 1,
    visibility: 'visible',
  },
});

// Tooltip style
export const tooltipStyle = css({
  position: 'absolute',
  bottom: 'calc(100% + 4px)',
  left: '50%',
  transform: 'translateX(-50%)',
  backgroundColor: 'gray.700',
  color: 'white',
  padding: '1',
  borderRadius: '0.25rem',
  fontSize: 'xs',
  fontWeight: 'normal',
  whiteSpace: 'nowrap',
  zIndex: 10,
  opacity: 0,
  visibility: 'hidden',
  transition: 'opacity 0.2s, visibility 0.2s',
  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
  pointerEvents: 'none', // Prevents the tooltip from interfering with clicks
});

// Keyboard shortcut badge style for the tooltip
export const shortcutBadgeStyle = css({
  display: 'inline-block',
  backgroundColor: 'rgba(255, 255, 255, 0.15)',
  color: 'white',
  borderRadius: '3px',
  padding: '0 4px',
  margin: '0 2px',
  fontSize: '10px',
  fontWeight: 'bold',
  fontFamily: 'monospace',
  border: '1px solid rgba(255, 255, 255, 0.3)',
  boxShadow: '0 1px 0 rgba(0, 0, 0, 0.2)',
});

interface TooltipProps {
  label?: string;
  keyboardShortcut?: string;
}

/**
 * Reusable tooltip component that can show a label with an optional keyboard shortcut
 */
export const Tooltip: React.FC<TooltipProps> = ({
  label,
  keyboardShortcut,
}) => {
  if (!label && !keyboardShortcut) return null;

  return (
    <span className={`tooltip ${tooltipStyle}`}>
      {label}
      {keyboardShortcut && (
        <>
          {' '}
          <span className={shortcutBadgeStyle}>{keyboardShortcut}</span>
        </>
      )}
    </span>
  );
};

export const buttonStyle = cva({
  base: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.25rem',
    padding: '0.5rem 0.75rem',
    borderRadius: '0.375rem',
    fontWeight: 600,
    fontFamily: 'sans-serif',
    fontSize: 'label',
    textDecoration: 'none',
    // TODO: remove local reset
    background: 'none',
    boxShadow: 'none',
    border: 'none',
    _hover: {
      bg: '#7f22f8',
      cursor: 'pointer',
    },
    _disabled: {
      _hover: {
        cursor: 'default',
      },
      opacity: 0.6,
    },
    '& .label': {
      display: {
        base: 'none',
        sm: 'inline',
      },
    },
  },
  variants: {
    type: {
      primary: {
        backgroundColor: 'brand',
        color: 'white',
      },
      secondary: {
        border: '1px solid {colors.brand}',
        color: '{colors.brand}',
        _hover: {
          bg: 'bg.interactiveContainerHover',
        },
      },
      tertiary: {
        color: '#e8d6ff',
        _hover: { bg: 'bg.interactiveContainerHover' },
      },
      danger: {
        backgroundColor: 'status.bad',
        color: 'white',
      },
    },
  },
  defaultVariants: {
    type: 'primary',
  },
});

const Button: React.FC<ButtonProps> = props => {
  const buttonClassName = props.keyboardShortcut
    ? `${buttonStyle({ type: props.type })} ${withTooltipStyle}`
    : buttonStyle({ type: props.type });

  return (
    <button
      className={buttonClassName}
      onClick={props.handleClick}
      disabled={props.disabled}
      type={props.htmlType || 'button'}
      form={props.form}
      formAction={props.formAction}
      formEncType={props.formEncType}
      formMethod={props.formMethod}
      formNoValidate={props.formNoValidate}
      formTarget={props.formTarget}
      aria-label={
        props.keyboardShortcut
          ? `${props.label} (Shortcut: ${props.keyboardShortcut})`
          : props.label
      }
    >
      {props.icon && (
        <Icon
          icon={props.icon}
          size={16}
          color={
            props.type === 'secondary'
              ? '#9747FF'
              : props.type === 'tertiary'
              ? '#e8d6ff'
              : 'white'
          }
        />
      )}{' '}
      {props.showLabel === false ? null : (
        <span className={'label'}>{props.label}</span>
      )}
      <Tooltip keyboardShortcut={props.keyboardShortcut} />
    </button>
  );
};

export default Button;
